import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import Hero from "../components/Hero";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
const config = require("../../content/meta/config");

const TagTemplate = props => {
  const {
    pageContext: { tag },
    data: {
      posts: { edges: posts = [] },
      site: {
        siteMetadata: { facebook, cover }
      },
      bgDesktop: {
        resize: { src: desktop }
      },
      bgTablet: {
        resize: { src: tablet }
      },
      bgMobile: {
        resize: { src: mobile }
      },
    }
  } = props;


  const backgrounds = {
    desktop,
    tablet,
    mobile
  };

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Hero
            isTag={true}
            backgrounds={backgrounds}
            theme={theme}
            title={tag}
          />
          // <Article theme={theme} title={tag} cover={cover}>
          //   <header>
          //     <Headline theme={theme}>
          //       <span>Posts in tags</span> <FaTag />
          //       {tag}
          //     </Headline>
          //     <p className="meta">
          //       There {totalCount > 1 ? "are" : "is"} <strong>{totalCount}</strong> post
          //       {totalCount > 1 ? "s" : ""} in the tags.
          //     </p>
          //     <List edges={edges} theme={theme} />
          //   </header>
          // </Article>
        )}
      </ThemeContext.Consumer>
      {/*<hr ref={this.separator} />*/}
      <ThemeContext.Consumer>
        {theme => <Blog posts={posts} theme={theme} />}
      </ThemeContext.Consumer>


      <ThemeContext.Consumer>
        {theme => <Footer config={config} theme={theme} />}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

TagTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default TagTemplate;

// eslint-disable-next-line no-undef
export const tagsQuery = graphql`
  query PostsBytags($tag: String) {
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___prefix], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            prefix
          }
          excerpt
          timeToRead
          frontmatter {
            title
            subtitle
            date
            icon {
              childImageSharp {
                bgDesktop: resize(width: 400, height: 400, quality: 90, cropFocus: CENTER) {
                  src
                }
                bgTablet: resize(width: 250, height: 250, quality: 90, cropFocus: CENTER) {
                  src
                }
                bgMobile: resize(width: 100, height: 100, quality: 90, cropFocus: CENTER) {
                  src
                }
              }
            }
            tags
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;
